/** @jsx jsx */
import * as React from "react";
import { jsx, Link as TLink, Box } from "theme-ui";
import { Link } from "gatsby";
import ItemTags from "@lekoarts/gatsby-theme-minimal-blog/src/components/item-tags";
import theme, { yirBackground } from "../gatsby-plugin-theme-ui";

type BlogListItemProps = {
  post: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    banner?: {
      childImageSharp: {
        resize: {
          src: string;
        };
      };
    };
    tags?: {
      name: string;
      slug: string;
    }[];
  };
  showTags?: boolean;
};

const BlogListItem = ({ post, showTags = true }: BlogListItemProps) => (
  <Box mb={4} sx={{ width: ["100%", "50%", "33%"], height: 225, px: "8px" }}>
    <TLink
      as={Link}
      to={post.slug}
      sx={{ fontSize: [1, 1], color: yirBackground }}
    >
      <div
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          borderRadius: 16,
          overflow: "hidden",
          borderLeft: "3px none transparent",
          borderBottom: "1px none transparent",
          transition: "transform 50ms",
          ":hover": {
            // borderLeft: "3px solid rgb(240, 85, 55)",
            // borderBottom: "1px solid rgb(240, 85, 55)",
            boxShadow: "-6px 10px 0px rgb(240, 85, 55)",
            transform: "scale(1.025)"
          }
        }}
      >
        <div sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div
            sx={{
              backgroundImage: `url('${post.banner?.childImageSharp.resize.src}')`,
              backgroundSize: "100% auto",
              backgroundPosition: "top center",
              width: "100%",
              height: "100%"
            }}
          ></div>
          <div
            sx={{
              background: "#FFF",
              pt: 2,
              px: 3
            }}
          >
            {post.title}
            <p
              sx={{
                color: `secondary`,
                mt: 1,
                a: { color: `secondary` },
                fontSize: 12
              }}
            >
              <time>{post.date}</time>
              {post.tags && showTags && (
                <React.Fragment>
                  {" - "}
                  <ItemTags tags={post.tags} />
                </React.Fragment>
              )}
            </p>
          </div>
        </div>
      </div>
    </TLink>
  </Box>
);

export default BlogListItem;
